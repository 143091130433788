export function parseFilter(filterArr: string[], filterByStarter: string, currencyCode: string): string {
  const ccode = currencyCode?.toLowerCase() || '';
  const countryIdentifier = ccode !== 'usd' && ccode !== 'eur' && ccode !== '' ? `_${ccode}` : '';
  let filterPayload = '';
  // TODO: use locale or country code to properly filter currency
  filterArr.forEach((filter: string, i: number) => {
    const regMatch = /[ 0-9A-Za-z]/g;
    const filterSlug = (filter.replace(/-/g, 'TO').match(regMatch) || []).join('');
    const operand = i === 0 ? ' AND ' : ' OR ';
    const operandPlacer = filterByStarter?.length === 0 && operand === ' AND ' ? '' : operand;
    filterPayload += operandPlacer + `sell_price${countryIdentifier}:${filterSlug}`;
  });
  return filterPayload;
}

export function getAisIndex(): string {
  const config = useRuntimeConfig();
  const ctsApiEnv = config?.public?.environment;
  const regionLocaleProperties = useRegionLocaleProperties();
  const region = regionLocaleProperties.value?.regionCode || 'us';
  const envSlug = ctsApiEnv === 'production' ? '' : ctsApiEnv + '_';
  return `${region.toLowerCase()}_${envSlug}v2_product_index`;
}

function getOptionIndex(options: any, state: any) {
  let locatedIndex = 0;
  options.find((option: any, i: number) => {
    if (option?.id === state.bcVariant?.id) {
      locatedIndex = i;
      return true;
    }
    return false;
  });
  return locatedIndex;
}

export function getVariantSkuFromBase(productData: any, state: any) {
  const productVariations = productData?.product_offerings?.product_variations;
  const options = productVariations?.length ? productVariations?.map((option: any) => option?.bc_variant) : [];
  const optionIndex = getOptionIndex(options, state);
  const hold = productVariations && productVariations[optionIndex] ? productVariations[optionIndex] : null;
  return hold.bc_variant?.sku;
}
